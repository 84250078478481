import rounding from '@/util/rounding'

export default {
  data() {
    return {
      zotaForm: {
        accountNumber: '',
        amount: '',
        notes: '',
      },
      rate: 0.0,
      congoCountryCode: '7031',
      countrySetup: {
        6459: {
          currency: 'UGX',
          header: 'deposit.uganda.header',
          country: 'deposit.uganda.country',
          logo: 'bankUganda',
        }, // Uganda
        4917: {
          currency: 'RWF',
          header: 'deposit.rwanda.header',
          country: 'deposit.rwanda.country',
          logo: 'bankUganda',
        }, // Rwanda
        6996: {
          currency: 'ZMW',
          header: 'deposit.zambia.header',
          country: 'deposit.zambia.country',
          logo: 'bankZambia',
        }, // Zambia
        7031: { currency: 'USD', header: 'deposit.congo.header', country: 'deposit.congo.country', logo: 'bankCongo' }, // Congo
        4688: {
          currency: 'XAF',
          header: 'deposit.cameroon.header',
          country: 'deposit.cameroon.country',
          logo: 'bankCameroon',
        }, // Cameroon
        3865: {
          currency: 'BIF',
          header: 'deposit.burundi.header',
          country: 'deposit.burundi.country',
          logo: 'bankBurundi',
        }, // Burundi
        4759: { currency: 'KES', header: 'deposit.kenya.header', country: 'deposit.kenya.country', logo: 'bankKenya' }, // Kenya
        4611: { currency: 'GHS', header: 'deposit.ghana.header', country: 'deposit.ghana.country', logo: 'bankGhana' }, // Ghana
        7089: {
          currency: 'TZS',
          header: 'deposit.tanzania.header',
          country: 'deposit.tanzania.country',
          logo: 'bankTanzania',
        }, // Tanzania
      },
    }
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.zotaForm.amount, 2)
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2)
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    countryCurrency() {
      return this.countrySetup[this.countryCode].currency
    },
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.zotaForm.accountNumber = accountNumber
    },
    success(msg) {
      window.location = msg
    },
    submitForm() {
      this.$refs['zotaForm'].validate(valid => {
        if (valid) {
          this.loading = true
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success)
            })
            .catch(err => {
              this.loading = false
              this.errorMessage(this.$t('deposit.default.failed'))
            })
        } else {
          return false
        }
      })
    },
  },
}
